<template>
<div>
 <AppTopNav></AppTopNav>
  <AppLayoutCommon>
    <XtxBread v-if="nowPage === 'publish' || nowPage === 'needslist'">
      <XtxBreadItem :path="'/'">首页</XtxBreadItem>
      <XtxBreadItem>{{ pageText1 }}</XtxBreadItem>
    </XtxBread>
    <XtxBread v-if="nowPage === 'needsdetail'">
      <XtxBreadItem :path="'/'">首页</XtxBreadItem>
      <XtxBreadItem :path="'/publish/needslist'">{{ pageText1 }}</XtxBreadItem>
      <XtxBreadItem>{{ pageText2 }}</XtxBreadItem>
    </XtxBread>
    <div class="publish-router-class">
      <router-view></router-view>
    </div>
  </AppLayoutCommon>
  <AppFooter v-if="nowPage === 'needslist' || nowPage === 'needsdetail'" />
</div>
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'
import AppFooter from '@/components/AppFooter'
import AppTopNav from '@/components/AppTopNav'
export default {
  data () {
    return {
      pageText1: '',
      pageText2: '',
      nowPage: ''
    }
  },
  components: {
    AppLayoutCommon,
    AppTopNav,
    AppFooter
  },
  created () {
    this.setBreadItem(this.$route)
  },
  beforeRouteUpdate (to) {
    this.setBreadItem(to)
  },
  methods: {
    setBreadItem (route) {
      this.nowPage = route.meta.page
      if (route.meta.page === 'publish') {
        this.pageText1 = '发起需求'
      } else if (route.meta.page === 'needslist') {
        this.pageText1 = '最新求购'
        this.pageText2 = ''
      } else if (route.meta.page === 'needsdetail') {
        this.pageText1 = '最新求购'
        this.pageText2 = '求购详情'
        this.length = 2
      }
    }
  }
}
</script>

<style lang="less">
.publish-router-class {
  .ivu-input {
    border: 1px solid #dcdee2 !important;
    padding: 4px 7px !important;
  }

  .ivu-table-tbody {
    td {
      vertical-align: top;
      padding: 5px 0;
      height: auto;
    }
  }

  .ivu-btn-error {
    background-color: #d12b23;
  }
}
</style>
